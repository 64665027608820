
const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/lina111/"
        target="_blank"
        rel="noreferrer"
        >
          <img alt="linkedin-link" src="../assets/linkedin.png" />
      </a>

      <a
        className="hover:opacity-50 transition duration-500"
        href="https://github.com/linahello"
        target="_blank"
        rel="noreferrer"
        >
          <img alt="github-link" src="../assets/github.png" />
      </a>

      <a
        className="hover:opacity-50 transition duration-500"
        href="https://twitter.com/LinaDev_"
        target="_blank"
        rel="noreferrer"
        >
          <img alt="twitter-link" src="../assets/twitter.png" />
      </a>

      

    </div>
  )
}

export default SocialMediaIcons;